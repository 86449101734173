import { regexMach } from '@smu-chile/pkg-unimarc-components/shared/helpers'
import {
  IFormValidations,
  IPaymentValidation
} from 'shared/interfaces/IFormValidations'

export interface IPaypmentValues {
  [key: string]: string | undefined
  name?: string
  email?: string
  rut?: string
  phone?: string
}

export const initialState = {
  giftCardQuantity: '1',
  beneficiaries: [
    {
      name: '',
      email: ''
    }
  ]
}

export const initialPaymentState = {
  name: '',
  email: '',
  phone: '',
  rut: ''
}

export const validationSingleState: IFormValidations = {
  giftCardQuantity: true,
  giftCardAmount: false,
  beneficiaries: [
    {
      name: false,
      email: false
    }
  ]
}

export const validationMultipleState: IFormValidations = {
  giftCardQuantity: false,
  giftCardAmount: false,
  beneficiaries: []
}

export const validationPaymentState: IPaymentValidation = {
  name: false,
  email: false,
  phone: false,
  rut: false
}

export const checkGiftcardFormValidation = (
  validationData: IFormValidations
) => {
  if (typeof validationData === 'object') {
    const keys = Object.keys(validationData)

    for (const key of keys) {
      if (key === 'beneficiaries') {
        for (const beneficiary of validationData[key]) {
          if (!beneficiary.name || !beneficiary.email) return false
        }
      } else {
        if (!validationData[key]) return false
      }
    }

    return true
  }
  return false
}

export const setValitadionForm = (validationData, newData) => {
  const newValidation = { ...validationData }
  const key = Object.keys(newData)[0]?.split('-')[0] || null
  const index = Object.keys(newData)[0]?.split('-')?.[1]

  if (key && !index) {
    newValidation[key] = newData[Object.keys(newData)[0]]
  } else {
    newValidation.beneficiaries[index][key] = newData[Object.keys(newData)[0]]
  }

  return newValidation
}

export const checkPeymentFormValidation = (
  validationData: IPaymentValidation
) => {
  if (typeof validationData === 'object') {
    const keys = Object.keys(validationData)

    for (const key of keys) {
      if (!validationData[key]) return false
    }

    return true
  }
  return false
}

export const giftcardFieldsValidation = (
  key,
  value,
  setErrorMesage,
  label?
) => {
  if (regexMach('email', key) && value !== '') {
    if (
      !new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/).test(
        value.target.value.toString()
      )
    ) {
      setErrorMesage('Por favor verifica el correo ingresado')
      return false
    }
  }
  if (regexMach('name', key)) {
    if (
      !new RegExp(
        /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆŠŽ∂ð' ]+$/u
      ).test(value?.target?.value?.toString())
    ) {
      setErrorMesage('Por favor escribe el nombre sin caracteres especiales')
      return false
    }

    if (value?.target?.value?.length < 2) {
      setErrorMesage('Por favor ingrese mínimo 2 caracteres')
      return false
    }

    if (value?.target?.value?.length > 150) {
      setErrorMesage('El nombre debe tener máximo 150 caracteres')
      return false
    }
  }
  if (regexMach('giftCardQuantity', key)) {
    if (!value || value === '' || value === label) {
      setErrorMesage('Selecciona una cantidad')
      return false
    }
  }
  if (regexMach('giftCardAmount', key)) {
    if (!value || value === '' || value === label) {
      setErrorMesage('Selecciona un monto')
      return false
    }
  }
  if (regexMach('message', key) && value !== '') {
    if (value.length > 150) {
      setErrorMesage('Por favor escribe un mensaje con menos de 150 caracteres')
      return false
    }
  }
  if (regexMach('phone', key)) {
    if (!new RegExp(/^[0-9]{8}$$/).test(value.target.value.toString())) {
      setErrorMesage('Por favor escribe un teléfono válido')
      return false
    }
  }
  if (regexMach('rut', key)) {
    if (value?.target?.value?.length < 11) {
      setErrorMesage('Por favor ingrese un RUT válido')
      return false
    }
    if (
      !new RegExp(/\b[0-9|.]{1,10}-[K|k|0-9]/).test(
        value?.target?.value?.toString()
      )
    ) {
      setErrorMesage('Por favor ingrese un RUT válido')
      return false
    }
  }
  setErrorMesage(null)
  return true
}

export const transformRut = (value) => {
  const rawRut = value.target.value.replace(/[^0-9kK]/g, '')?.slice(0, 9)
  let finalRut = rawRut

  const verifyDigit = rawRut[rawRut.length - 1]
  const units = rawRut.length > 1 ? rawRut.slice(-4, -1) : null
  const hundreds = rawRut.length > 3 ? rawRut.slice(-7, -4) : null
  const millions = rawRut.length > 6 ? rawRut.slice(0, -7) : null

  if (units) finalRut = `${units}-${verifyDigit}`
  if (hundreds) finalRut = `${hundreds}.${units}-${verifyDigit}`
  if (millions) finalRut = `${millions}.${hundreds}.${units}-${verifyDigit}`

  value.target.value = finalRut
  return value
}
