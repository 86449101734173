import {
  ContentfulResponse,
  getContentfulData,
  IContentfulBasicEntry,
  IContentfulResponse,
  QueryClient
} from '@smu-chile/pkg-unimarc-hooks'
import { IOptiornsEntriesRequest } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IContentful'
import { SeoInfoProps } from '../interfaces/Home'

export interface OptionsProps {
  options: IOptiornsEntriesRequest
}

export interface ContentComponents {
  contentOptions: OptionsProps
  key?: string
}

export const getContentFulComponents = async ({
  contentOptions,
  key = 'components'
}: ContentComponents) => {
  const queryClient = new QueryClient()
  const contentFulKey = `contentful_${contentOptions.options['content_type']}`
  await queryClient.prefetchQuery(contentFulKey, () => {
    return getContentfulData(contentOptions)
  })
  const data = queryClient.getQueryData(contentFulKey) as IContentfulResponse

  if (data?.items?.length === 0) {
    return {
      response: {},
      queryClient,
      seoInfo: {
        pageTitle: '',
        description: '',
        robotTag: ''
      }
    }
  }

  const response: any = {}
  const populatedData = new ContentfulResponse(data)
    .populateEntries()
    .getResponse()

  const components = populatedData?.items?.[0]?.fields
    ? (populatedData.items?.[0]?.fields?.[`${key}`] as IContentfulBasicEntry[])
    : []
  for (const item of components) {
    if (!item.sys?.contentType?.sys?.id || !item?.sys?.id) continue
    response[`${item?.sys?.contentType?.sys?.id}_${item?.sys?.id}`] =
      item.fields
  }

  const seoInfo: SeoInfoProps = {
    pageTitle: (data?.['items']?.[0]?.fields?.title as string) || '',
    description: (data?.['items']?.[0]?.fields?.description as string) || ''
  }

  return {
    response: response ?? {},
    queryClient,
    seoInfo
  }
}
