import { CSSProperties } from 'react'
import { Container } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

export interface LayoutProps {
  children: React.ReactNode
  backgroundColor?: CSSProperties['backgroundColor']
  isMobile?: boolean
}

export const Layout = ({
  backgroundColor,
  children
}: LayoutProps): React.ReactElement => {
  const background =
    backgroundColor ?? getGlobalStyle('--color-neutral-gray-white')

  return (
    <Container
      backgroundColor={background}
      isWrap
    >
      {children}
    </Container>
  )
}
