import { IFormValues } from 'shared/utils/hooks'

export const updateBeneficiaryInfo = (
  formData: IFormValues,
  key: string,
  index: string,
  value: string
) => {
  const first = formData.beneficiaries?.slice(0, Number(index))
  const last = formData.beneficiaries?.slice(Number(index) + 1)
  return {
    ...formData,
    beneficiaries: [
      ...first,
      { ...formData.beneficiaries[index], [key]: value },
      ...last
    ]
  }
}
